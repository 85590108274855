





































































































import { Component, Vue } from 'vue-property-decorator'

import {
  getSaturdayVipDayInfo,
  receiveVipGiftPackage
} from '@/pages/videoGame/api'
import {
  callAppFunc_setNavColor,
  callAppFunc_setTitleFontColor,
  callAppFunc_setNavCloseIcon,
  callAppFunc_setNavBackIcon,
  callAppFunc_GoWallet
} from '@/common/utils/bridge'
import { isIOS } from '@/common/utils'
import { CommonModule } from '@/store/modules/common'
@Component({})
export default class extends Vue {
  taskList = [
    {
      name: '消费2000币返250币',
      present: '250币',
      target: 2000
    },
    {
      name: '消费8000币返850币',
      present: '850币',
      target: 8000
    },
    {
      name: '消费18000币返1500币',
      present: '1500币',
      target: 18000
    },
    {
      name: '消费50000币返3500币',
      present: '3500币',
      target: 50000
    },
    {
      name: '消费180000币返10000币',
      present: '10000币',
      target: 180000
    }
  ]
  curUsed = 0
  dayInfo = {
    vipName: '',
    userReceiveState: 0,
    saturdayDayFlag: false,
    saturdayConsumeAmount: 0,
    nextVipName: '',
    maxVipLevelFlag: false,
    activityVipReceive: {
      coin: 0,
      integral: 0
    },
    nextActivityVipReceive: {
      coin: 0,
      integral: 0
    }
  }
  vipLevelBtnMap: any = {
    V1: 'gift_btn2',
    V2: 'gift_btn3',
    V3: 'gift_btn4',
    V4: 'gift_btn5',
    V5: 'gift_btn6',
    V6: 'gift_btn7',
    V7: 'gift_btn8',
    V8: 'gift_btn9',
    V9: 'gift_btn10',
    V10: 'gift_btn12'
  }
  get curBtn() {
    //是否是周六
    if (!this.dayInfo.saturdayDayFlag) {
      return 'gift_btn11'
    } else if (!this.dayInfo.vipName) {
      //是否开通vip
      return 'gift_btn1'
    } else if (this.dayInfo.userReceiveState == 0) {
      //是否领取
      return ''
    } else {
      if (this.dayInfo.maxVipLevelFlag) {
        return 'gift_btn12'
      }
      return this.vipLevelBtnMap[this.dayInfo.vipName]
    }
  }

  async getData() {
    const { data } = await getSaturdayVipDayInfo()
    Object.assign(this.dayInfo, data)
    if (!this.dayInfo.saturdayDayFlag) {
      this.$toast('活动未开始，周六来哦～')
    }
  }
  reviceLoading = false
  async reviceGift() {
    if (!this.dayInfo.saturdayDayFlag) {
      return
    }
    if (this.dayInfo.userReceiveState == 1 && this.dayInfo.maxVipLevelFlag) {
      return
    }
    if (!this.dayInfo.vipName) {
      this.goWallet()
      return
    }
    if (
      this.dayInfo.userReceiveState == 1 &&
      !this.dayInfo.maxVipLevelFlag &&
      this.dayInfo.saturdayDayFlag
    ) {
      this.goWallet()
      return
    }

    console.log('领取')
    if (this.reviceLoading) {
      return
    }
    this.reviceLoading = true
    try {
      await receiveVipGiftPackage()
      this.$toast('领取成功')
      this.getData()
    } finally {
      this.reviceLoading = false
    }
  }

  get vipGiftCoin() {
    if (this.dayInfo.maxVipLevelFlag) {
      return this.dayInfo.activityVipReceive.coin
    }
    if (this.dayInfo.userReceiveState == 0) {
      return this.dayInfo.activityVipReceive.coin
    } else {
      return this.dayInfo.nextActivityVipReceive.coin
    }
  }
  get vipGiftIntegral() {
    if (this.dayInfo.maxVipLevelFlag) {
      return this.dayInfo.activityVipReceive.integral
    }
    if (this.dayInfo.userReceiveState == 0) {
      return this.dayInfo.activityVipReceive.integral
    } else {
      return this.dayInfo.nextActivityVipReceive.integral
    }
  }
  goWallet() {
    if (isIOS) {
      callAppFunc_GoWallet()
    } else {
      this.$router.push('/wallet')
    }
  }
  async mounted() {
    await CommonModule.getToken()
    this.getData()
  }
  created() {
    callAppFunc_setNavColor({
      navColor: '#576BFD'
    })
    callAppFunc_setTitleFontColor({
      navFontColor: '#ffffff'
    })
    callAppFunc_setNavBackIcon({
      backIconUrl: 'https://qiniu.kigo.top/h5resource/icon_22_back_1%402x.png'
    })
    callAppFunc_setNavCloseIcon({
      closeIconUrl:
        'https://qiniu.kigo.top/h5resource/icon_22_close_white_2%402x.png'
    })
  }
  destroyed() {
    callAppFunc_setNavColor({
      navColor: '#ffffff'
    })
    callAppFunc_setTitleFontColor({
      navFontColor: '#000000'
    })
    callAppFunc_setNavBackIcon({
      backIconUrl:
        'https://qiniu.kigo.top/h5resource/icon_22_back_black%402x.png'
    })
    callAppFunc_setNavCloseIcon({
      closeIconUrl:
        'https://qiniu.kigo.top/h5resource/icon_22_close_black_2%402x.png'
    })
  }
}
